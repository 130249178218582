import React, { useState, useEffect } from 'react';
import { fetchUtils, Admin, Resource,  ListGuesser, EditGuesser, ShowGuesser } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import './App.css';

import authProvider from './authProvider';
import themeReducer from './themeReducer';
import { Login, Layout } from './layout';
import { Dashboard } from './dashboard';
import customRoutes from './routes';
import englishMessages from './i18n/en';

import visitors from './visitors';
import orders from './orders';
import products from './products';
import invoices from './invoices';
import categories from './categories';
import reviews from './reviews';

import dataProviderFactory from './dataProvider';
import fakeServerFactory from './fakeServer';
import simpleRestProvider from './ra-strapi-rest';
import Cookies from './helpers/Cookies';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = Cookies.getCookie('token')
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
}

const dataProvider = simpleRestProvider('https://api.app.grocing.com/partners', httpClient);



const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'fr') {
        return import('./i18n/fr').then(messages => messages.default);
    }

    // Always fallback on english
    return englishMessages;
}, 'en');

const App = () => {
    // const [dataProvider, setDataProvider] = useState(null);

    // useEffect(() => {
    //     let restoreFetch;

    //     const fetchDataProvider = async () => {
    //         restoreFetch = await fakeServerFactory(
    //             process.env.REACT_APP_DATA_PROVIDER
    //         );
    //         const dataProviderInstance = await dataProviderFactory(
    //             process.env.REACT_APP_DATA_PROVIDER
    //         );
    //         setDataProvider(
    //             // GOTCHA: dataProviderInstance can be a function
    //             () => dataProviderInstance
    //         );
    //     };

    //     fetchDataProvider();

    //     return restoreFetch;
    // }, []);

    if (!dataProvider) {
        return (
            <div className="loader-container">
                <div className="loader">Loading...</div>
            </div>
        );
    }

    return (
        <Admin
            title=""
            dataProvider={dataProvider}
            customReducers={{ theme: themeReducer }}
            customRoutes={customRoutes}
            authProvider={authProvider}
            // dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
        >
            <Resource name="leads" list={ListGuesser} show={ShowGuesser} />
            <Resource name="converted" list={ListGuesser} show={ShowGuesser} />
        </Admin>
    );
};

export default App;
